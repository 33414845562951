import React from "react";
import { useOverrides } from "@quarkly/components";
import { Link, List, Section } from "@quarkly/widgets";
const defaultProps = {
	"background-color": "--dark",
	"text-align": "center",
	"padding": "32px 0",
	"quarkly-title": "Footer-1",
	"color": "#0E1317",
	"background": "--color-darkL2"
};
const overrides = {
	"list": {
		"kind": "List",
		"props": {
			"margin": "0px 0px 0px 0px",
			"padding": "12px 0px 12px 0px",
			"list-style-type": "none",
			"as": "ul",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/index",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "Ana sayfa"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "/services",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "Hizmetler"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"href": "/faq",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": <>
				{" "}FAQ
			</>,
			"display": "none"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "/contacts",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "Kişiler"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"href": "mailto:hello@company.com",
			"text-decoration-line": "none",
			"variant": "--base",
			"color": "--light",
			"hover-color": "--primary",
			"children": <>
				+905161630034
				<br />
				<br />
				Gümüşdere mah. Yaprak sokak Sumaland,{" "}
				<br />
				34450 Sarıyer/İstanbul, Türkiye
				<br />
				<br />
				Copyright @2024. OceanWave Surf Academy
			</>
		}
	}
};

const Footer12 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<List {...override("list")}>
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Link {...override("link2")} />
			<Link {...override("link3")} />
		</List>
		<Link {...override("link4")} />
		{children}
	</Section>;
};

Object.assign(Footer12, { ...Section,
	defaultProps,
	overrides
});
export default Footer12;